<template>
  <div style="overflow: scroll; margin-bottom: 160px;" id="rfq-view-table" class="repairer-rfq-view-part-list-container">

  <div class="d-flex flex-wrap">
  <b-table-simple table-class="ps-scroll repairer-rfq-view-part-list-table-container-2" id="rfq-view-table">
    <b-thead id="rfq-view-thead">
      <b-tr>
        <b-th id="rfq-view-line-column" class="part-list-line align-top" :style="{left: getLeftPosition('rfq-view-line-column')}">
          <div>Line</div>
        </b-th>
        <b-th id="rfq-view-part-list-column" class="part-list-part-name align-top" :style="{left: getLeftPosition('rfq-view-part-list-column')}">
          <div>Parts List</div>
          <span v-on:click="supplierPrice.sortBy('name')"></span>
        </b-th>
        <b-th id="rfq-view-part-number-column" class="part-list-number text-center align-top" :style="{left: getLeftPosition('rfq-view-part-number-column')}">
          <div class="d-flex justify-content-center">
            <div class="div-number">Part Number</div>
            <span class="ml-2" v-on:click="supplierPrice.sortBy('number')"></span>
          </div>
        </b-th>
        <b-th id="rfq-view-qty-column" class="part-list-qty text-center align-top" :style="{left: getLeftPosition('rfq-view-qty-column')}">
          <div>
            <div class="div-qty">Qty</div>
            <span v-on:click="supplierPrice.sortBy('qty')"></span>
          </div>
        </b-th>
        <b-th v-if="isExistListColumn && ((position === 3 || position === 4 || position === 5))"
              id="rfq-view-rrp-column"
              :style="{left: getLeftPosition('rfq-view-rrp-column')}"
              class="part-list-rrp text-center align-top">
          <div class="d-flex justify-content-center">
            <div class="div-qty">List</div>
            <span v-on:click="supplierPrice.sortBy('rrp')"></span>
          </div>
        </b-th>
        <b-th class="text-center align-top"
              v-for="supplier in supplierPrice.orderedSuppliers"
              :id="'rfq-view-supplier-' + supplier.id"
              style="position: relative; z-index: 1;"
              :class="{'selected-col':supplierPrice.selectedSupplierId === supplier.id && isClickable, 'highlight-col': supplierPrice.selectedSupplierId !== supplier.id && isClickable}"
              :key="supplier.orderId ? supplier.orderId : supplier.id">
          <div class="supplier-icon text-center">
            <img v-if="supplier.logo" :src="supplier.logo">
            <i v-else class='bx bx-car'></i>
          </div>
          <div
              v-b-tooltip.hover="{
                                customClass: 'ps-tooltip',
                                html: true,
                                title:`Quote NBR - ${supplier.dmsQuoteNumber}`,
                                placement:'leftbottom',
                                disabled: supplier.dmsQuoteNumber == null
                            }"
              class="supplier-name text-center"
          >{{ supplier.name }}
          </div>
          <div class="supplier-type text-center" style="padding-top:15px;">
            {{ supplier.type }}
          </div>
          <div class="part-list-company-color" :style="{ background: getSupplierColor(supplier) }"></div>
        </b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <!--            <b-tr v-if="position!==1">-->
      <!--                <b-td class="subheader subheader-p-c" :colspan="isExistListColumn?5:4">Pricing Completed <span-->
      <!--                        class="subheader-border-right"></span></b-td>-->
      <!--                <b-td class="p-c-value text-center"-->
      <!--                      v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.orderId ? supplier.orderId : supplier.id">-->
      <!--                    {{ supplier.pricingCompletedStr }}-->
      <!--                </b-td>-->
      <!--            </b-tr>-->
      <b-tr v-if="position !== 2">
        <b-td class="subheader subheader-p-c info-line" :colspan="isExistListColumn?5:4" :style="{left: getLeftPosition('info-line')}">Supplier Quote Nbr<span
            class="subheader-border-right"></span></b-td>
        <b-td class="p-c-value text-center"
              style="position: relative; z-index: 1;"
              v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.orderId ? supplier.orderId : supplier.id">
          {{ supplier.supplierQuoteNumber }}
        </b-td>
      </b-tr>
      <b-tr v-for="(part, index) in allParts" class="rfq-view-part-line" :key="part.id">
        <b-td class="part-list-line rfq-view-line-cell" :style="{left: getLeftPosition('rfq-view-line-column')}">
          <div class="part-list-line-number">
            {{ index + 1 }}
          </div>
        </b-td>
        <b-td class="part-list-part-name rfq-view-part-list-cell"
              :style="{left: getLeftPosition('rfq-view-part-list-column')}"
              :class="{ 'color-grey': !part.isOffer,
                                'linked': partIsLinked(part),
                                'has-alt': part.altPartNames.length > 0 }">
          <div class="part-list-part-name__group">
            <div :class="{'part-list-item-alt-name': !part.isOriginalPart, 'part-list-item-name-original-original-linked': part.isOriginalPart && partIsLinked(part)}" class="part-list-item-name">
              {{ part.name }}
            </div>
            <template v-if="partIsLinked(part) || part.altPartNames.length > 0">
              <div :class="{'linked': partIsLinked(part)}" class="part-list-item-alt-name">
                {{ getAltPartName(part.id) }}
              </div>
            </template>
          </div>

          <!-- icons -->
          <div class="part-list-part-name__icon-group">
            <i v-if="partIsLinked(part)"
               class='bx bx-link part-list-part-name__icon'
               v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:`Linked to <br>${getPartLinkedName(part)}`, placement:'leftbottom'}"
            ></i>
          </div>
        </b-td>
        <b-td class="part-list-number text-center rfq-view-part-number-cell" style="padding-right: 0px; padding-left: 0px;"
              :style="{left: getLeftPosition('rfq-view-part-number-column')}"
              :class="{'color-grey': !part.isOffer}">
          <div v-if="part.number === getPartNumber(part.id) || isEmpty(getPartNumber(part.id))"
               class="part-list-item-number">
            {{ part.number }}
          </div>
          <div v-else class="part-list-item-number">
            {{ getPartNumber(part.id) }}
          </div>
        </b-td>
        <b-td class="part-list-qty text-center rfq-view-qty-cell" :style="{left: getLeftPosition('rfq-view-qty-column')}">
          <div v-if="!partIsWithNoCost(part)" class="part-list-item-qty"
               :class="{'part-list-item-qty-ordered': status==='Ordered' }">
            <i v-if="part.isOffer && isNotCancelledPartOrHasCreditByOriginalPartId(part.id)" class='bx bxs-check-circle check-icon'
               v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltop--table qty-tooltip', html: true, title: getTooltipForOrderedPart(part), placement:'leftbottom'}"
            ></i>
            <span :class="{'disabled-qty': !part.isOffer}">{{ part.qty }} </span>
            <i v-if="part.originalQty - part.qty < 0"
               v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltop--table qty-tooltip', html: true, title: 'Qty Changed', placement:'leftbottom'}"
               class="bx bx-up-arrow-alt qty-icon-arrow-up qty-icon-arrow"
               style="transform: translateY(0px)">
            </i>
            <i v-else-if="part.originalQty - part.qty > 0"
               v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltop--table qty-tooltip', html: true, title: 'Qty Changed', placement:'leftbottom'}"
               class="bx bx-down-arrow-alt qty-icon-arrow-down qty-icon-arrow"
               style="transform: translateY(0px)"></i>
          </div>
        </b-td>
        <b-td
            v-if="isExistListColumn && ((position === 2 || position === 3 || position === 4) || (position===2 && status==='Cancelled'))"
            :style="{left: getLeftPosition('rfq-view-rrp-column')}"
            class="part-list-rrp text-center rfq-view-rrp-cell" :class="{'color-grey': !part.isOffer}">
          <div v-if="part.isOriginalPart || !part.isDelivery"
               :class="{
                            'green-cell': (!isEmpty(OEMSupplier) && OEMSupplier.getOffer(part).firstRrp > 0 && OEMSupplier.getOffer(part).firstRrp<part.rrp),
                            'red-cell': (!isEmpty(OEMSupplier) && OEMSupplier.getOffer(part).firstRrp > 0 && OEMSupplier.getOffer(part).firstRrp>part.rrp)
                         }"
               class="part-list-item-rrp"> {{ part.rrp ? part.rrp : '' | formatMoney }}
          </div>
          <div v-else
               class="part-list-item-rrp"></div>
        </b-td>

        <!-- Supplier's Prices -->
        <b-td v-for="supplier in supplierPrice.orderedSuppliers"
              :key="supplier.orderId ? supplier.orderId : supplier.id"
              @click="priceClicked(supplier, part)"
              class="part-list-supplier text-center"
              :class="{
                        'part-list-supplier-non-non-active': (part.isParentPart || part.isAdditional),
                        'offer-checked' : isOfferChecked(supplier, part),
                        'color-grey': !(isOfferChecked(supplier, part)) && (!isCanSelect(supplier.getOffer(part), supplier)) || !part.isParentPart && !part.isAdditional && !supplierPrice.isOfferOrdered(supplier.getOffer(part)) && !isCanReOrder,


                        'clickable-cell': !(!isCanReOrder || (!isCanSelect(supplier.getOffer(part), supplier)) || (supplierPrice.isOfferOrdered(supplier.getOffer(part)) && !(!part.isOriginalPart && partIsWithNoCost(part))) || part.isDelivery || (!part.isOriginalPart && (partIsWithNoCost(part)))),
                        'disable-part': !isCanReOrder || (!isCanSelect(supplier.getOffer(part), supplier)) || (supplierPrice.isOfferOrdered(supplier.getOffer(part)) && !(!part.isOriginalPart && partIsWithNoCost(part))) || part.isDelivery || (!part.isOriginalPart && (partIsWithNoCost(part))),
                        'selected-disable-part': (supplier.isRfqToSupplierStatusDecline || part.isDelivery || (!part.isOriginalPart && (partIsWithNoCost(part)))) && (part.id && supplier.isPartSelected(part.id) && position > 2),
                        'part-list-supplier-without-border': !isClickable || !isOfferClickable(supplier, part),
                        'part-list-supplier-non-non-active': part.isParentPart || part.isAdditional,
                        'table-b-table-supplier-width': status === 'Submitted' || status === 'Cancelled',
                        'cancelled-prt': isCancelledPart(supplier.getOffer(part), supplier),
                        'part-has-credit': isPartHasCredit(supplier.getOffer(part), supplier),
                        'padding-top-and-bottom-0px': isShowPercents(supplier, part) || isShowMUP(supplier, part),
              }">

          <template v-if="part.isOriginalPart">
            <div v-if="partIsWithNoCost(part) && (supplier.type === 'OEM-GEN' || supplier.type === 'OEM-PAR')"
                 class="part-list-item-price font-12">
              Linked
            </div>
            <div v-else-if="supplier.getOffer(part)"
                 class="part-list-item-price font-12 part-list-item-price--priced d-flex align-items-center justify-content-center"
                 :class="{'has-icon': supplier.getOffer(part) && (supplier.getOffer(part).flagged || supplier.getOffer(part).comment.length > 0), }">
              <div
                  class="part-list-item-price__value text-right"
                  :class="{
                                   'green-cell': (supplier.getOffer(part).firstPrice > 0 && supplier.getOffer(part).firstPrice<supplier.getOffer(part).price),
                                    'red-cell':(supplier.getOffer(part).firstPrice > 0 && supplier.getOffer(part).firstPrice>supplier.getOffer(part).price),
                                    'price-red-blinking': isPriceGrayBlinking(part, supplier),
                                }"
              >
                {{ supplier.getOffer(part).price | formatMoney }}
              </div>

              <div v-if="isShowPercents(supplier, part)"
                   :class="{
                    'price-red-blinking': isPriceGrayBlinking(part, supplier)
                 }"
                   style="font-size:10px; text-align: center; position: absolute; bottom: 5px; width: 100%;"
                   class="">
                {{ getPercentForTooltip(supplier.getOffer(part), part, supplier) }}%  of List
              </div>
              <div v-if="isShowMUP(supplier, part)"
                   style="font-size:10px; text-align: center; position: absolute; bottom: 5px; width: 100%;"
                   class="">
                + M/UP
              </div>

              <!-- icon group -->
              <div class="part-list-item-price__icon-group" style="display: flex; width: auto; min-width: auto;">
<!--                <i v-if="supplier.getOffer(part).stockComment"-->
<!--                   v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: supplier.getOffer(part).stockComment, placement:'leftbottom'}"-->
<!--                   class='bx bx-note font-20 text-warning'>-->
<!--                </i>-->
<!--                <i class="bx bx-message-detail font-20 text-success"-->
<!--                   v-if="supplier.getOffer(part).stock === 2 && supplier.getOffer(part).comment"-->
<!--                   v-b-tooltip.hover="{customClass: 'ps-tooltip', title: supplier.getOffer(part).comment, placement:'left'}">-->
<!--                </i>-->
<!--                <i class="bx bx-flag font-20"-->
<!--                   v-if="supplier.getOffer(part).flagged"-->
<!--                   v-b-tooltip.hover="{customClass: 'ps-tooltip', title: getStockLabel(supplier.getOffer(part)), placement:'left'}">-->
<!--                </i>-->
                <i class="font-20"
                   v-if="supplier.getOffer(part).stock > 0 || supplier.getOffer(part).stock <= 7"
                   style="margin-left: 5px;"
                   :ref="'icon-' + supplier.id + '-' + supplier.getOffer(part).id"
                   :class="{
                       'icon-with-text': isShowPercents(supplier, part) || isShowMUP(supplier, part),
                      // In Stock
                      'bx bx-check-circle green-icon': supplier.getOffer(part).stock === 1,
                      // EX Warehouse
                      'bx bx-check-circle orange-icon': supplier.getOffer(part).stock === 6,
                      'bx bx-check-circle icon-orange-blinking': supplier.getOffer(part).stock === 6 && getStockComment(part.id),
                      // Low Stock
                      'bx bx-check-circle blue-icon': supplier.getOffer(part).stock === 2,
                      // No Stock, No Longer Available, Not Applicable
                      'bx bx-minus-circle gray-icon': supplier.getOffer(part).stock === 3 || supplier.getOffer(part).stock === 5 || supplier.getOffer(part).stock === 7,
                      // Back Order
                      'bx bx-check-circle red-icon': supplier.getOffer(part).stock === 4,
                      'bx bx-check-circle red-icon icon-red-blinking': supplier.getOffer(part).stock === 4 && getStockComment(part.id),
                    }"
                >
                </i>
                <i v-if="supplier.getOffer(part) && supplier.getOffer(part).nonReturnable"  v-b-tooltip.hover="{customClass: 'ps-tooltip non-returnable-tooltip', html: true, title: '<b>Marked Non-Returnable Part</b>', placement:'leftbottom'}" class='bx bx-error font-20 orange-icon'></i>
              </div>
            </div>
            <div v-else>
            </div>
          </template>

          <template v-else-if="part.isOptional && supplier.getOffer(part)">
            <div
                class="part-list-item-price font-12 part-list-item-price--priced d-flex align-items-center justify-content-center"
                :class="{
                    'has-icon': (supplier.getOffer(part).flagged || supplier.getOffer(part).comment.length > 0),
            }">
              <div class="part-list-item-price__value text-right" :class="{'price-red-blinking': isPriceGrayBlinking(part, supplier),}">
                {{ supplier.getOffer(part).price | formatMoney }}
              </div>

              <div v-if="isShowPercents(supplier, part)"
                   :class="{
                    'price-red-blinking': isPriceGrayBlinking(part, supplier)
                 }"
                   style="font-size:10px; text-align: center; position: absolute; bottom: 5px; width: 100%;"
                   class="">
                {{ getPercentForTooltip(supplier.getOffer(part), part, supplier) }}%  of List
              </div>
              <div v-if="isShowMUP(supplier, part)"
                   style="font-size:10px; text-align: center; position: absolute; bottom: 5px; width: 100%;"
                   class="">
                + M/UP
              </div>

              <div class="part-list-item-price__icon-group" style="display: flex; width: auto; min-width: auto;">
<!--                <i v-if="supplier.getOffer(part).stockComment"-->
<!--                   v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: supplier.getOffer(part).stockComment, placement:'leftbottom'}"-->
<!--                   class='bx bx-note font-20 text-warning'>-->
<!--                </i>-->
<!--                <i class="bx bx-message-detail font-20 text-success"-->
<!--                   v-if="supplier.getOffer(part).stock === 2 && supplier.getOffer(part).comment"-->
<!--                   v-b-tooltip.hover="{customClass: 'ps-tooltip', title: supplier.getOffer(part).comment, placement:'left'}">-->
<!--                </i>-->
<!--                <i class="bx bx-flag font-20"-->
<!--                   v-if="supplier.getOffer(part).flagged"-->
<!--                   v-b-tooltip.hover="{customClass: 'ps-tooltip', title: getStockLabel(supplier.getOffer(part)), placement:'left'}">-->
<!--                </i>-->

                <i class="font-20"
                   v-if="supplier.getOffer(part).stock > 0 || supplier.getOffer(part).stock <= 7"
                   style="margin-left: 5px;"
                   :ref="'icon-' + supplier.id + '-' + supplier.getOffer(part).id"
                   :class="{
                     'icon-with-text': isShowPercents(supplier, part) || isShowMUP(supplier, part),
                      // In Stock
                      'bx bx-check-circle green-icon': supplier.getOffer(part).stock === 1,
                      // EX Warehouse
                      'bx bx-check-circle orange-icon': supplier.getOffer(part).stock === 6,
                      'bx bx-check-circle icon-orange-blinking': supplier.getOffer(part).stock === 6 && getStockComment(part.id),
                      // Low Stock
                      'bx bx-check-circle blue-icon': supplier.getOffer(part).stock === 2,
                      // No Stock, No Longer Available, Not Applicable
                      'bx bx-minus-circle gray-icon': supplier.getOffer(part).stock === 3 || supplier.getOffer(part).stock === 5 || supplier.getOffer(part).stock === 7,
                      // Back Order
                      'bx bx-check-circle red-icon': supplier.getOffer(part).stock === 4,
                      'bx bx-check-circle red-icon icon-red-blinking': supplier.getOffer(part).stock === 4 && getStockComment(part.id),
                    }"
                >
                </i>
                <i v-if="supplier.getOffer(part) && supplier.getOffer(part).nonReturnable"  v-b-tooltip.hover="{customClass: 'ps-tooltip non-returnable-tooltip', html: true, title: '<b>Marked Non-Returnable Part</b>', placement:'leftbottom'}" class='bx bx-error font-20 orange-icon'></i>
              </div>
            </div>
          </template>
          <template v-else-if="supplier.getOffer(part)">
            <div v-if="partIsWithNoCost(part) || !supplier.parts[0] || !supplier.parts[0][part.id]"
                 class="part-list-item-price font-12">
              {{ partIsWithNoCost(part) ? 'Linked' : '' }}
            </div>
            <div v-else
                 class="part-list-item-price font-12 part-list-item-price--priced d-flex align-items-center justify-content-center"
                 :class="{
                                'has-icon': (supplier.parts[0][part.id].flagged || supplier.parts[0][part.id].comment.length > 0) && !supplier.parts[0][part.id].isDelivery,
                                'green-cell': (supplier.getOffer(part).firstPrice > 0 && supplier.getOffer(part).firstPrice<supplier.getOffer(part).price),
                                'red-cell':(supplier.getOffer(part).firstPrice > 0 && supplier.getOffer(part).firstPrice>supplier.getOffer(part).price)
                              }">
              <div class="part-list-item-price__value text-right">
                {{ supplier.parts[0][part.id].price | formatMoney }}
              </div>

              <div v-if="isShowPercents(supplier, part)"
                   :class="{
                    'price-red-blinking': isPriceGrayBlinking(part, supplier)
                 }"
                   style="font-size:10px; text-align: center; position: absolute; bottom: 5px; width: 100%;"
                   class="">
                {{ getPercentForTooltip(supplier.getOffer(part), part, supplier) }}%  of List
              </div>
              <div v-if="isShowMUP(supplier, part)"
                   style="font-size:10px; text-align: center; position: absolute; bottom: 5px; width: 100%;"
                   class="">
                + M/UP
              </div>

              <!-- icon group -->
              <div v-if="!supplier.parts[0][part.id].isDelivery" class="part-list-item-price__icon-group" style="display: flex; width: auto; min-width: auto;">
<!--                <i v-if="supplier.parts[0][part.id].stockComment"-->
<!--                   v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title: supplier.parts[0][part.id].stockComment, placement:'leftbottom'}"-->
<!--                   class='bx bx-note font-20 text-warning'>-->
<!--                </i>-->
<!--                <i class="bx bx-message-detail font-20 text-success"-->
<!--                   v-if="supplier.parts[0][part.id].stock === 2 && supplier.parts[0][part.id].comment"-->
<!--                   v-b-tooltip.hover="{customClass: 'ps-tooltip', title: supplier.parts[0][part.id].comment, placement:'left'}">-->
<!--                </i>-->
<!--                <i class="bx bx-flag font-20"-->
<!--                   v-if="supplier.parts[0][part.id].flagged"-->
<!--                   v-b-tooltip.hover="{customClass: 'ps-tooltip', title: getStockLabel(supplier.parts[0][part.id]), placement:'left'}">-->
<!--                </i>-->
                <i class="font-20"
                   v-if="supplier.getOffer(part).stock > 0 || supplier.getOffer(part).stock <= 7"
                   style="margin-left: 5px;"
                   :ref="'icon-' + supplier.id + '-' + supplier.getOffer(part).id"
                   :class="{
                     'icon-with-text': isShowPercents(supplier, part) || isShowMUP(supplier, part),
                      // In Stock
                      'bx bx-check-circle green-icon': supplier.getOffer(part).stock === 1,
                      // EX Warehouse
                      'bx bx-check-circle orange-icon': supplier.getOffer(part).stock === 6,
                      'bx bx-check-circle icon-orange-blinking': supplier.getOffer(part).stock === 6 && getStockComment(part.id),
                      // Low Stock
                      'bx bx-check-circle blue-icon': supplier.getOffer(part).stock === 2,
                      // No Stock, No Longer Available, Not Applicable
                      'bx bx-minus-circle gray-icon': supplier.getOffer(part).stock === 3 || supplier.getOffer(part).stock === 5 || supplier.getOffer(part).stock === 7,
                      // Back Order
                      'bx bx-check-circle red-icon': supplier.getOffer(part).stock === 4,
                      'bx bx-check-circle red-icon icon-red-blinking': supplier.getOffer(part).stock === 4 && getStockComment(part.id),
                    }"
                >
                </i>
                <i v-if="supplier.getOffer(part) && supplier.getOffer(part).nonReturnable"  v-b-tooltip.hover="{customClass: 'ps-tooltip non-returnable-tooltip', html: true, title: '<b>Marked Non-Returnable Part</b>', placement:'leftbottom'}" class='bx bx-error font-20 orange-icon'></i>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="part-list-item-price font-12">
            </div>
          </template>

          <template v-if="rfqId && $refs['icon-' + supplier.id + '-' + supplier.getOffer(part).id]">
            <template v-if="isShowSimpleTooltip(supplier.getOffer(part), supplier) || rfq.status !== 'Ordered'">
              <b-tooltip
                  :target="() => $refs['icon-' + supplier.id + '-' + supplier.getOffer(part).id]?.at(0)"
                  :customClass="getTooltipClass(supplier.getOffer(part), supplier, index)"
                  :placement="getTooltipPlacement(supplier.getOffer(part), supplier)"
                  triggers="hover">
                <div v-html="getPartTooltip(supplier.getOffer(part), supplier, supplier.getOffer(part).stock)"></div>
              </b-tooltip>
            </template>
            <template v-else>
              <b-tooltip
                  :target="() => $refs['icon-' + supplier.id + '-' + supplier.getOffer(part).id]?.at(0)"
                  :customClass="getTooltipClass(supplier.getOffer(part), supplier, index)"
                  :placement="getTooltipPlacement(supplier.getOffer(part), supplier)"
                  @show="onShowTooltip(supplier.getOffer(part), supplier)"
                  triggers="hover">

                <template
                    v-if="!_isEmpty(purchasePriceSelector?.display) && !_isEmpty(supplier.getOffer(part).purchasePriceFigures)">
                  <div class="tooltip-each-price-information-block">
                    <div class="tooltip-each-price-information-block-inner">

                      <div class="d-flex">
                        <p class="col-4 p-0 text-left font-weight-600">Part Number </p>
                        <p class="col-7 text-left p-0">{{ supplier.getOffer(part).partNumber }}</p>
                        <p v-if="supplier.getOffer(part).purchasePriceFigures.sellPrice !== null && part.stock[supplier.id] !== 3"
                           class="col-1 text-left p-0">
                          <i v-if="supplier.getOffer(part).purchasePriceFigures.editMode"
                             @click="stopEditPartsTooltip(supplier.getOffer(part), supplier)"
                             class='bx bxs-edit clickable'
                             style="font-size:18px; transform: translateX(5px)"></i>
                          <i v-else @click="editPartsTooltip(supplier.getOffer(part), supplier)"
                             class='bx bx-edit clickable' style="font-size:18px; transform: translateX(5px)"></i>
                        </p>
                      </div>
                      <template v-if="!supplier.isSupplierTypeOEMGEN && supplier.getOffer(part) && supplier.getOffer(part).oePartNumber">
                        <div class="d-flex">
                          <p class="col-4 p-0 text-left font-weight-600">OE Ref Number </p>
                          <p class="col-8 text-left p-0">{{ supplier.getOffer(part).oePartNumber }}</p>
                        </div>
                      </template>
                      <template v-if="purchasePriceSelector.display.stockStatus">
                        <div class="d-flex">
                          <p class="col-4 text-left p-0 font-weight-600">Stock Status </p>
                          <div class="d-flex align-items-center">
                            <p class="text-left p-0" style="margin-right: 8px;">
                              {{ getStockLabel(supplier.getOffer(part).stock) }}
                            </p>
                            <i class="font-20"
                               style="    transform: translateY(-7px);"
                               v-if="part.stock[supplier.id] > 0 || part.stock[supplier.id] <= 7"
                               :class="{
                                  // In Stock
                                  'bx bx-check-circle green-icon': part.stock[supplier.id] === 1,
                                  // EX Warehouse
                                  'bx bx-check-circle orange-icon': part.stock[supplier.id] === 6,
                                  'bx bx-check-circle icon-orange-blinking': part.stock[supplier.id] === 6 && getStockComment(part.id),
                                  // Low Stock
                                  'bx bx-check-circle blue-icon': part.stock[supplier.id] === 2,
                                  // No Stock, No Longer Available, Not Applicable
                                  'bx bx-minus-circle gray-icon': part.stock[supplier.id] === 3 || part.stock[supplier.id] === 5 || part.stock[supplier.id] === 7,
                                  // Back Order
                                  'bx bx-check-circle red-icon': part.stock[supplier.id] === 4,
                                  'bx bx-check-circle red-icon icon-red-blinking': part.stock[supplier.id] === 4 && getStockComment(part.id),
                                }"
                            >
                            </i>
                          </div>
                        </div>
                        <!--                        Added by #269.29-->
                        <template v-if="getStockCommentForTooltip(supplier.getOffer(part), supplier)">
                          <div class="d-flex" style="margin-bottom: 10px;">
                            <p class="col-4 text-left p-0 font-weight-600"></p>
                            <div class="d-flex align-items-center">
                              <p class="text-left p-0" style="margin-right: 8px;">
                                {{ getStockCommentForTooltip(supplier.getOffer(part), supplier) }}</p>
                            </div>
                          </div>
                        </template>

                      </template>

                      <template v-if="part.stock[supplier.id] !== 3">
                        <template v-if="isOemGenPartHasLinkedParts(part, supplier)">
                          <template v-if="purchasePriceSelector.display.listPrice">
                            <div class="d-flex align-items-center mb-2">
                              <p class="col-4 text-left p-0 mb-0 font-weight-600">Combined <br/> List Price</p>
                              <div class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                                {{ formatValue(getCombinedListPrice(OEMSupplier, OEMSupplier.getOffer(part), supplier.getOffer(part).purchasePriceFigures.listPrice)) }}
                              </div>
                            </div>
                          </template>
                        </template>

                        <template v-else-if="purchasePriceSelector.display.listPrice">
                          <div class="d-flex align-items-center mb-2">
                            <p class="col-4 text-left p-0 mb-0 font-weight-600">List Price</p>
                            <div class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                              {{ formatValue(supplier.getOffer(part).purchasePriceFigures.listPrice) }}
                            </div>
                          </div>
                        </template>

                        <template v-if="purchasePriceSelector.display.sellPrice && isShowChargePrice(supplier)">
                          <div class="d-flex align-items-center mb-2">
                            <p class="col-4 text-left p-0 mb-0 font-weight-600">Sell Price</p>
                            <div class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                              {{ getChargePriceForTooltip(supplier.getOffer(part), part, supplier) }}
                            </div>

                            <template v-if="supplier.getOffer(part).purchasePriceFigures.editMode">
                              <p class="col-4 text-left p-0 mb-0 pl-3 font-weight-600">

                                <input
                                    v-model="newPercentForTooltip"
                                    :ref="'percent-input-' + supplier.getOffer(part).id"
                                    @change="updateTooltipPercent(supplier.getOffer(part), supplier, part)"
                                    @input="supplier.getOffer(part).purchasePriceFigures.sellPricePercent = newPercentForTooltip"
                                    class="form-control tooltip-each-price-information-input font-weight-normal "
                                    style="padding-left: 5px; padding-right: 5px; width:32px; max-width: 32px; min-width: 32px; display: inline-block;"
                                />
                                <span class="text-left p-0 mb-0  font-weight-600" style="margin-left: 3px">% of List</span>
                              </p>
                            </template>
                            <template v-else-if="getPercentForTooltip(supplier.getOffer(part), part, supplier)">
                              <p class="col-4 text-left p-0 mb-0 pl-3 font-weight-600">
                                {{ getPercentForTooltip(supplier.getOffer(part), part, supplier) }}% of List</p>
                            </template>

                            <div
                                v-if="supplier.getOffer(part).purchasePriceFigures.sellPrice !== null
                          && Number(getCostPricePercentageForTooltip(supplier.getOffer(part), part, supplier)) !== Number(purchasePriceSelector.listPricePercents.markUp)"
                                class="col-1 p-0 tooltip-checkbox"
                            >
                              <label class="form-check-inline checkbox tooltip-purchase-checkbox">
                                <input class="form-check-input"
                                       @change="onChangedOnCheckbox(1, supplier.getOffer(part), supplier, part)"
                                       v-model="supplier.getOffer(part).purchasePriceFigures.sellPriceCheck"
                                       type="checkbox"
                                >
                                <span @click="onChangedSellPriceCheckBox(supplier, part)" class="icon clickable" style="transform: translate(1px, 3px);">
                                <i class='bx bx-check'></i></span>
                              </label>
                            </div>

                          </div>
                        </template>

                        <template v-if="purchasePriceSelector?.display?.costPrice">
                          <div class="d-flex mt-3 mb-2">
                            <p class="col-4 text-left p-0"></p>
                            <p class="col-4 text-left p-0 pb-2 tooltip-each-price-information-line font-weight-600">
                              Purchase Price</p>
                            <p v-if="purchasePriceSelector.display.markUpPercentage"
                               class="col-4 text-left p-0 pb-2 pl-3 tooltip-each-price-information-line font-weight-600">
                              Markup %
                            </p>
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <p class="col-4 text-left p-0 mb-0 font-weight-900">Cost Price</p>
                            <div class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-900">
                              {{ formatValue(supplier.getOffer(part).purchasePriceFigures?.costPrice) }}
                            </div>
                            <p :class="{
                                    'red-cell': Number(getCostPricePercentageForTooltip(supplier.getOffer(part), part, supplier)) < Number(purchasePriceSelector.listPricePercents.markUp),
                                    'green-cell': Number(getCostPricePercentageForTooltip(supplier.getOffer(part), part, supplier)) >= Number(purchasePriceSelector.listPricePercents.markUp)
                                  }"
                               class="col-4 text-left p-0 mb-0 pl-3 font-weight-900">
                              <!--                            <span-->
                              <!--                                v-if="Number(getCostPricePercentageForTooltip(supplier.getOffer(part))) < Number(purchasePriceSelector.listPricePercents.markUp)">-</span>-->
                              <!--                            <span v-else>+</span>-->
                              <!--                            Removal By #296.30-->
                              <span>{{ getCostPricePercentageForTooltip(supplier.getOffer(part), part, supplier) }}%</span>
                            </p>
                          </div>
                        </template>

                        <template v-if="purchasePriceSelector.display.profitValue">
                          <div class="d-flex align-items-center mb-2">
                            <p class="col-4 text-left p-0 mb-0 font-weight-600">Profit Value</p>
                            <div class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                              <template v-if="supplier.getOffer(part).purchasePriceFigures.sellPricePercent">
                                {{
                                  formatValue((Number(supplier.getOffer(part).purchasePriceFigures.sellPricePercent) * Number(getListPrice(supplier.getOffer(part), part, supplier) ) / 100) - Number(supplier.getOffer(part).purchasePriceFigures?.costPrice))
                                }}
                              </template>
                              <template v-else>
                                {{ formatValue(supplier.getOffer(part).purchasePriceFigures.profitValue) }}
                              </template>
                            </div>
                          </div>
                        </template>

                        <template
                            v-if="purchasePriceSelector.display.discountPrice && supplier.getOffer(part).purchasePriceFigures.discountPrice > 0">
                          <div class="d-flex" style="margin-top: 13px;">
                            <p class="col-4"></p>
                            <p class="col-8 tooltip-each-price-information-line"></p>
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <p class="col-4 text-left p-0 mb-0 font-weight-600">Disc Price</p>
                            <div class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                              {{ formatValue(supplier.getOffer(part).purchasePriceFigures.discountPrice) }}
                            </div>
                            <template v-if="purchasePriceSelector.display.profitValue">
                              <p class="col-4 text-left p-0 mb-0 pl-3 font-weight-900">
                            <span>
                              {{
                                formatPercent((100 * supplier.getOffer(part).purchasePriceFigures.discountProfitValue) / supplier.getOffer(part).purchasePriceFigures.discountPrice, 2)
                              }}%</span>
                              </p>
                            </template>
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <p class="col-4 text-left p-0 mb-0 font-weight-600">Disc Profit Value</p>
                            <div class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                              {{ formatValue(supplier.getOffer(part).purchasePriceFigures.discountProfitValue) }}
                            </div>
                          </div>
                        </template>

                        <template
                            v-if="supplier.getOffer(part).purchasePriceFigures.sellPrice !== null
                          && Number(getCostPricePercentageForTooltip(supplier.getOffer(part), part, supplier)) < Number(purchasePriceSelector.listPricePercents.markUp)">
                          <div class="d-flex" style="margin-top: 13px;">
                            <p class="col-4"></p>
                            <p class="col-8 tooltip-each-price-information-line"></p>
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <p class="col-4 text-left p-0 mb-0 font-weight-600">M/UP % Price</p>

                            <template v-if="supplier.getOffer(part).purchasePriceFigures.editMode">
                              <div class="col-4 text-left p-0 mb-0 font-weight-600" style="border: 1px solid transparent !important;">
                                <number-formatter
                                    v-model="supplier.getOffer(part).purchasePriceFigures.markUpAndPrice"
                                    class="form-control tooltip-each-price-information-input font-weight-normal text-center"
                                    @change="updateTooltipPercent(supplier.getOffer(part), supplier, part)"
                                    style="border: 1px solid #1c1f3940 !important;"
                                    format="$0,0.00"
                                ></number-formatter>
                              </div>
                            </template>
                            <template v-else>
                              <!--                            <div v-if="formatValue(supplier.getOffer(part).purchasePriceFigures.discountProfitValue)>0"-->
                              <!--                                 class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal">-->
                              <!--                              {{ formatValue(supplier.getOffer(part).purchasePriceFigures.discountProfitValue) }}-->
                              <!--                            </div>-->
                              <!--                            <div v-else class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal">-->
                              <!--                              {{ formatValue(supplier.getOffer(part).price) }}-->
                              <!--                            </div>-->
                              <div class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-normal" style="border: 1px solid #1c1f3940 !important;">
                                <template v-if="supplier.getOffer(part).purchasePriceFigures.markUpAndPrice">
                                  {{ formatValue(supplier.getOffer(part).purchasePriceFigures.markUpAndPrice) }}
                                </template>
                                <template v-else>
                                  {{
                                    formatValue(supplier.getOffer(part).price + (supplier.getOffer(part).price * (Number(purchasePriceSelector.listPricePercents.markUp) / 100)))
                                  }}
                                </template>
                              </div>
                            </template>
                            <p
                                class="col-4 text-left p-0 mb-0 font-weight-900"
                                :class="{
                                'pl-3': !supplier.getOffer(part).purchasePriceFigures.editMode,
                                'width-3-markup-and-price': supplier.getOffer(part).purchasePriceFigures.editMode,
                                'fixed-position-markup-and-price': supplier.getOffer(part).purchasePriceFigures.editMode,
                              }"
                            >
                              <span>{{ getMarkUpPercentForTooltip(supplier.getOffer(part)) }}%</span>
                            </p>

                            <div class="col-1 p-0 tooltip-checkbox">
                              <label class="form-check-inline checkbox tooltip-purchase-checkbox">
                                <input class="form-check-input"
                                       @change="onChangedOnCheckbox(2, supplier.getOffer(part), supplier, part)"
                                       v-model="supplier.getOffer(part).purchasePriceFigures.markUpAndPriceCheck"
                                       type="checkbox"
                                >
                                <span @click="onChangedMarkUpCheckBox(supplier, part)" class="icon clickable" style="transform: translate(1px, 3px);">
                                <i class='bx bx-check'></i></span>
                              </label>
                            </div>
                          </div>

                          <template v-if="purchasePriceSelector.display.profitValue">
                            <div class="d-flex align-items-center mb-2">
                              <p class="col-4 text-left p-0 mb-0 font-weight-600">Profit Value</p>
                              <div class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                                {{
                                  formatValue(getMUpProfitValue(supplier, part))
                                }}
                              </div>
                            </div>
                          </template>
                        </template>

                        <template v-if="isPartHasLinkedParts(supplier.getOffer(part).id, supplier)">
                          <div class="d-flex" style="margin-top: 13px;">
                            <p class="col-4"></p>
                            <p class="col-8 tooltip-each-price-information-line"></p>
                          </div>
                          <template v-if="purchasePriceSelector.display.listPrice">
                            <div class="d-flex align-items-center mb-2">
                              <p class="col-4 text-left p-0 mb-0 font-weight-600">Combined <br/> List Price</p>
                              <div class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                                {{ formatValue(getCombinedListPrice(supplier, supplier.getOffer(part))) }}
                              </div>
                            </div>
                          </template>

                          <template v-if="purchasePriceSelector.display?.costPrice">
                            <div class="d-flex mt-3 mb-2">
                              <p class="col-4 text-left p-0"></p>
                              <p class="col-4 text-left p-0 pb-2 tooltip-each-price-information-line font-weight-600">
                                Purchase Price</p>
                              <p v-if="purchasePriceSelector.display.markUpPercentage"
                                 class="col-4 text-left p-0 pb-2 pl-3 tooltip-each-price-information-line font-weight-600">
                                Markup %
                              </p>
                            </div>
                            <div class="d-flex align-items-center mb-2">
                              <p class="col-4 text-left p-0 mb-0 font-weight-900">Combined <br/> Cost Price</p>
                              <div class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-900">
                                {{ formatValue(getCombinedCostPrice(supplier, supplier.getOffer(part))) }}
                              </div>
                              <p :class="{
                                    'red-cell': Number(getCombinedCostPriceMarkUp(supplier, supplier.getOffer(part))) < Number(purchasePriceSelector.listPricePercents.markUp),
                                    'green-cell': Number(getCombinedCostPriceMarkUp(supplier, supplier.getOffer(part))) >= Number(purchasePriceSelector.listPricePercents.markUp)
                                  }"
                                 class="col-4 text-left p-0 mb-0 pl-3 font-weight-900">
                                <!--                            <span-->
                                <!--                                v-if="Number(getCostPricePercentageForTooltip(supplier.getOffer(part))) < Number(purchasePriceSelector.listPricePercents.markUp)">-</span>-->
                                <!--                            <span v-else>+</span>-->
                                <!--                            Removal By #296.30-->
                                <span>{{ getCombinedCostPriceMarkUp(supplier, supplier.getOffer(part)) }}%</span>
                              </p>
                            </div>
                          </template>

                          <template v-if="purchasePriceSelector.display.profitValue">
                            <div class="d-flex align-items-center mb-2">
                              <p class="col-4 text-left p-0 mb-0 font-weight-600">Profit Value</p>
                              <div class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                                {{ formatValue(getCombinedProfitValue(supplier, supplier.getOffer(part))) }}
                              </div>
                            </div>
                          </template>
                        </template>
                      </template>

                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="tooltip-each-price-information-block">
                    <div class="tooltip-each-price-information-block-inner">
                      <div class="d-flex">
                        <p class="col-4 p-0 text-left font-weight-600">Part Number </p>
                        <p class="col-7 text-left p-0">{{ getPartNumber(part.id) }}</p>
                      </div>
                      <div class="d-flex">
                        <p class="col-4 text-left p-0 font-weight-600">Stock Status </p>
                        <div class="d-flex align-items-center">
                          <p class="text-left p-0" style="margin-right: 8px;">
                            {{ getStockLabel(supplier.getOffer(part).stock) }}
                          </p>
                          <i class="font-20"
                             style="    transform: translateY(-7px);"
                             v-if="part.stock[supplier.id] > 0 || part.stock[supplier.id] <= 7"
                             :class="{
                                  // In Stock
                                  'bx bx-check-circle green-icon': part.stock[supplier.id] === 1,
                                  // EX Warehouse
                                  'bx bx-check-circle orange-icon': part.stock[supplier.id] === 6,
                                  'bx bx-check-circle icon-orange-blinking': part.stock[supplier.id] === 6 && getStockComment(part.id),
                                  // Low Stock
                                  'bx bx-check-circle blue-icon': part.stock[supplier.id] === 2,
                                  // No Stock, No Longer Available, Not Applicable
                                  'bx bx-minus-circle gray-icon': part.stock[supplier.id] === 3 || part.stock[supplier.id] === 5 || part.stock[supplier.id] === 7,
                                  // Back Order
                                  'bx bx-check-circle red-icon': part.stock[supplier.id] === 4,
                                  'bx bx-check-circle red-icon icon-red-blinking': part.stock[supplier.id] === 4 && getStockComment(part.id),
                                }"
                          >
                          </i>
                        </div>
                      </div>
                      <div v-if="getStockCommentForTooltip(supplier.getOffer(part), supplier)" class="d-flex">
                        <p>{{ getStockCommentForTooltip(supplier.getOffer(part), supplier) }}</p>
                      </div>

                      <div class="d-flex align-items-center mb-2">
                        <p class="col-4 text-left p-0 mb-0 font-weight-600">List Price</p>
                        <div class="col-8 p-0 form-control tooltip-each-price-information-input font-weight-normal">
                          {{ formatValue(getRrp(supplier.getOffer(part).originalPartId)) }}
                        </div>
                      </div>
                      <div class="d-flex align-items-center mb-2">
                        <p class="col-4 text-left p-0 mb-0 font-weight-900">Cost Price</p>
                        <div class="col-4 p-0 form-control tooltip-each-price-information-input font-weight-900">
                          {{ formatValue(supplier.getOffer(part).price) }}
                        </div>
                      </div>

                    </div>
                  </div>
                </template>
              </b-tooltip>
            </template>
          </template>
        </b-td>
      </b-tr>
      <b-tr v-if="position!==1">
        <b-td :style="{left: getLeftPosition('info-line')}" class="info-line subheader parts-footer text-right" :colspan="isExistListColumn?5:4">Pricing Totals <span
            class="subheader-border-right"></span></b-td>
        <b-td class="totals-value text-center"
              style="position: relative; z-index: 1;"
              v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.orderId ? supplier.orderId : supplier.id"
              :class="{'part-list-item-price': !supplier.priceSelected}"
        >
          {{ supplier.priceTotalCalc | formatMoney }}
        </b-td>
      </b-tr>
      <b-tr v-if="position!==1">
        <b-td :style="{left: getLeftPosition('info-line')}" class="info-line subheader parts-footer text-right" :colspan="isExistListColumn?5:4">Parts in Stock Today<span
            class="subheader-border-right"></span></b-td>
        <b-td class="footer-value text-center"
              style="position: relative; z-index: 1;"
              v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.orderId ? supplier.orderId : supplier.id"
              :class="{'part-list-item-price': !supplier.priceSelected}">
          {{ supplier.inStock }}/{{ supplier.countPartForInStock }}
        </b-td>
      </b-tr>
      <b-tr v-if="position!==1">
        <b-td :style="{left: getLeftPosition('info-line')}" class="info-line subheader parts-footer text-right" :colspan="isExistListColumn?5:4">Parts Delivery ETA<span
            class="subheader-border-right"></span></b-td>
        <b-td class="footer-value text-center"
              style="position: relative; z-index: 1;"
              v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.orderId ? supplier.orderId : supplier.id"
              :class="{'part-list-item-price': !supplier.priceSelected}"
        >
          {{ supplier.partsDeliveryETA }}
        </b-td>
      </b-tr>
      <b-tr v-if="position!==1">
        <b-td :style="{left: getLeftPosition('info-line')}" class="info-line subheader parts-footer text-right" :colspan="isExistListColumn?5:4">Discount Applied<span
            class="subheader-border-right"></span></b-td>
        <b-td class="footer-value text-center"
              style="position: relative; z-index: 1;"
              v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.orderId ? supplier.orderId : supplier.id"
              :class="{'part-list-item-price': !supplier.priceSelected}"
        >{{
            supplier.discount | percentage(2)
          }}
        </b-td>
      </b-tr>
      <b-tr v-if="position!==1">
        <b-td :style="{left: getLeftPosition('info-line')}" class="info-line subheader parts-footer text-right" :colspan="isExistListColumn?5:4">Profit Breakdown<span
            class="subheader-border-right"></span></b-td>
        <b-td class="footer-value text-center"
              style="position: relative; z-index: 1;"
              v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.orderId ? supplier.orderId : supplier.id"
              :class="{'part-list-item-price': !supplier.priceSelected}"
        >{{
            supplier.priceSavingOrdered | formatMoney
          }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-td :style="{left: getLeftPosition('info-line')}" class="info-line subheader parts-footer text-right" :colspan="isExistListColumn?5:4">Order Subtotal<span
            class="subheader-border-right"></span></b-td>
        <b-td class="totals-value text-center"
              style="position: relative; z-index: 1;"
              v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.orderId ? supplier.orderId : supplier.id"
              :class="{'part-list-item-price': !supplier.orderTotal}"
        >{{
            supplier.orderSubtotal | formatMoney
          }}
        </b-td>
      </b-tr>
      <b-tr>
        <b-td :style="{left: getLeftPosition('info-line')}" class="info-line subheader parts-footer text-right" :colspan="isExistListColumn?5:4">Grand Total (Incl GST)<span
            class="subheader-border-right"></span></b-td>
        <b-td class="totals-value text-center"
              style="position: relative; z-index: 1;"
              v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.orderId ? supplier.orderId : supplier.id"
              :class="{'part-list-item-price': !supplier.orderTotal}"
        >{{
            supplier.orderSubtotal * 1.1| formatMoney
          }}
        </b-td>
      </b-tr>
      <b-tr v-if="position!==1 && position!==2">
        <template>
          <b-td :style="{left: getLeftPosition('info-line')}" class="info-line subheader parts-footer text-right" :colspan="isExistListColumn?5:4">
            Order number link
            <span class="subheader-border-right"></span>
          </b-td>
          <b-td class="footer-value text-center"
                style="position: relative; z-index: 1;"
                v-for="supplier in supplierPrice.orderedSuppliers"
                :key="supplier.orderId ? supplier.orderId : supplier.id">
            <b-button
                v-for="order in supplier.orderIds"
                :key="order.id"
                style="margin-bottom: 5px"
                class="order"
                      :to="'/r/order/' + order.id"
                      v-if="order.number"
                      variant="link">
              {{ order.number }}
            </b-button>
          </b-td>
        </template>
      </b-tr>
      <b-tr v-if="position!==1" id="last-table-line">
        <b-td :style="{left: getLeftPosition('info-line')}" class="info-line subheader parts-footer text-right" :colspan="isExistListColumn?5:4">Your Rewards<span
            class="subheader-border-right"></span></b-td>
        <b-td class="footer-value text-center"
              style="position: relative; z-index: 1;"
              :class="{'part-list-item-price': !supplier.priceSelected}"
              v-for="supplier in supplierPrice.orderedSuppliers" :key="supplier.orderId ? supplier.orderId : supplier.id">{{
            supplier.reward
          }}
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>

    <div v-if="isCanReOrder" class="d-flex flex-column">
      <b-button
          class="form-control rfq-ordered-insurer-btn"
          :class="{'active-insurer-btn': selectedPriceSet === s.name}"
          v-for="s in orderedPriceSets"
          @click="$emit('handlePriceSetSelectorPressed', s.name)"
          :key="s.name">
        {{s.label}}
      </b-button>
      <b-button
          class="form-control rfq-ordered-order-btn"
          :class="{'rfq-ordered-order-btn-disabled': countOfSelectedParts == 0}"
          :disabled="countOfSelectedParts == 0"
          @click="orderCreate(selectedParts)">
        Order Selected Parts
      </b-button>
    </div>
  </div>
    <div
        class="topscroll"
        style="bottom: 0px; position: fixed; width: 600px; height: 5px; overflow-x: scroll; z-index: 5; background: white"
    >
      <div style="height: 1px" class="fake"></div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */

import _ from "lodash";
import dayjs from "dayjs";
import NumberFormatter from '../../../../components/utility/number-formatter'
import {mapGetters} from "vuex";
import tooltipOptions from './tooltipOptions'
import Axios from "axios";

export default {
  name: "RepairerPartListOrdered",
  mixins: [tooltipOptions],
  components: {
    NumberFormatter,
  },
  data() {
    return {
      isClickable: false,
      partFromSupplier: false,
      leftPositions: {},
      newPercentForTooltip: null,
    };
  },
  methods: {
    handleResize () {
      let parts = _.cloneDeep(this.supplierPrice.parts)
      this.supplierPrice.parts.splice(0, this.supplierPrice.parts.length)
      _.forEach(parts, (p) => {
        this.supplierPrice.parts.push(p)
      })
    },
    orderCreate: function (data, isNeedShowModals = true) {
      // let isHasNonReturnableSupp = _.some(this.supplierPrice.selectedSuppliers, (s) => {
      //   return _.some(s.selectedParts, (prtId) => {
      //     return s?.parts?.[prtId]?.nonReturnable
      //   })
      // })
      // if (isHasNonReturnableSupp && isNeedShowModals) {
      //   this.showNonReturnableModal1();
      //   return
      // }

      if (_.isEmpty(data)) {
        return
      }
      Axios.post('/ir/repairer/order/pre-create',
          {
            rfqId: this.rfqId,
            partsToOrders: data
          }).then(response => {
        //Redirect
        //this.$toast.success("Order has been created");
        this.$router.push({
          name: 'RepairerRfqOrder',
          props: true,
          params: {
            rfq_id: this.rfqId,
            selectedParts: this.supplierPrice.parts,
            rfq: this.rfq,
            isExtensionActive: this.isExtensionActive
          }
        })
      }).catch(error => {
        this.$toast.error('Order hasn\'t been created')
        console.log(error)
      })
    },
    isOfferChecked(supplier, part) {
      return (this.supplierPrice.isOfferOrdered(supplier.getOffer(part)) && !(!part.isOriginalPart && this.partIsWithNoCost(part))) || (!supplier.isRfqToSupplierStatusDecline && part.id && supplier.isPartSelected(part.id) && this.position > 2)
    },
    keyDownEvent(e) {
      if (!this.isPartListTab) {
        return
      }
      let el = document.getElementById('rfq-view-table')
      if (!el) {
        return
      }
      if (e.code == 'ArrowRight') {
        el.scrollLeft += 15
      } else if (e.code == 'ArrowLeft') {
        el.scrollLeft -= 15
      }
    },
    setFakeScroll() {

      let container = $('.repairer-rfq-view-part-list-container');
      let topscroll = $('.topscroll');

      $('.fake').width($('.repairer-rfq-view-part-list-table-container-2').width());
      $('.topscroll').width($('.repairer-rfq-view-part-list-container').width())

      if (document.getElementById('last-table-line').getBoundingClientRect().bottom + 13 <= window.innerHeight) {
        $('.topscroll').hide()
      } else {
        $('.topscroll').show()
      }

      topscroll.scroll(function(e){
        container.scrollLeft($(this).scrollLeft());
      });
      container.scroll(function(e){
        topscroll.scrollLeft($(this).scrollLeft());
      });
    },
    setLeftPosition() {
      this.handleResize()
      this.setFakeScroll()
      let lineWidth = document.getElementById('rfq-view-line-column').clientWidth;
      let partListWidth = document.getElementById('rfq-view-part-list-column').clientWidth;
      let partNumberWidth = document.getElementById('rfq-view-part-number-column').clientWidth;
      let qtyWidth = document.getElementById('rfq-view-qty-column').clientWidth;
      // let rrpWidth = document.getElementById('rfq-view-rrp-column').clientWidth;

      this.$nextTick(() => {
        this.$set(this.leftPositions, 'rfq-view-line-column', '0px')
        this.$set(this.leftPositions, 'rfq-view-part-list-column', lineWidth + 'px')
        this.$set(this.leftPositions, 'rfq-view-part-number-column', lineWidth + partListWidth + 'px')
        this.$set(this.leftPositions, 'rfq-view-qty-column', lineWidth + partListWidth + partNumberWidth + 'px')
        this.$set(this.leftPositions, 'rfq-view-rrp-column', lineWidth + partListWidth + partNumberWidth + qtyWidth + 'px')
        this.$set(this.leftPositions, 'info-line', '0px')
      })
    },
    getLeftPosition(type) {
      if (this.leftPositions[type]) {
        return this.leftPositions[type]
      }
      return '0px'
    },

    getTooltipForOrderedPart(part) {
      let result = 'Ordered';
      if (this.getOrderedParentNumber(part)) {
        result += `<br>` + 'Ordr Nbr: ' + this.getOrderedParentNumber(part)
      }
      if (this.getOrderedParentDate(part)) {
        result += `<br>` + this.getOrderedParentDate(part)
      }
      return result
    },
    getOrderedParentDate(part) {
      let result = false;
      for (let id in this.supplierPrice.orderedSuppliers) {
        let supplier = this.supplierPrice.orderedSuppliers[id];
        if (this.supplierPrice.isOfferOrdered(supplier.getOffer(part))) {
          let offer = _.find(this.supplierPrice.partsOrdered, (prt) => {
            return Number(prt.offerId) === Number(supplier.getOffer(part).id)
          })
          if (offer) {
            result = dayjs(offer.orderedOn).format("hh:mmA || DD/MM/YYYY");
          }
        }
      }
      return result
    },
    getOrderedParentNumber(part) {
      let result = false;
      for (let id in this.supplierPrice.orderedSuppliers) {
        let supplier = this.supplierPrice.orderedSuppliers[id];
        if (this.supplierPrice.isOfferOrdered(supplier.getOffer(part))) {
          let offer = _.find(this.supplierPrice.partsOrdered, (prt) => {
            return Number(prt.offerId) === Number(supplier.getOffer(part).id)
          })
          if (offer) {
            _.forIn(this.rfqOrders, (order) => {
              let orderedPart = _.find(order.partsOrdered, (p) => {
                return Number(p.id) === Number(offer.id);
              })
              if (orderedPart) {
                result = order.number
                return;
              }
            })
          }
        }
      }
      return result
    },
    isEmpty(value) {
      return _.isEmpty(value)
    },
    getPartFromSupplier(supplierParts, part) {
      if (part.isManual) {
        return {};
      }
      let find = _.find(supplierParts, {'originalPartId': Number(part.id)});
      console.log('find', find);
      if (find) {
        return find;
      }
      return false;
    },
    getStockComment (partId) {
      let result = _.trim(this.supplierPrice?.getStockComment(partId))?.substr(0, 15)
      if (this.supplierPrice?.getStockComment(partId)?.length > 15) {
        result += '...'
      }
      return result
    },
    isCancelledPartOrHasCredit(prt, supplier) {
      let id = supplier.rfqToSupplierId ? supplier.rfqToSupplierId : supplier.id
      let orderedPrt = _.find(this.rfq.partsOrdered, (p) => {
        return Number(p.rfqToSupplierId) === Number(id) && Number(p.offerId) == Number(prt.id)
      })
      return !!orderedPrt?.isCancelled ||  !!orderedPrt?.isHasCredit;
    },
    isNotCancelledPartOrHasCreditByOriginalPartId(prtId) {
      return _.some(this.rfq.partsOrdered, (p) => {
        let supplier = _.find(this.supplierPrice?.orderedSuppliers, (s) => {
          let id = s.rfqToSupplierId ? s.rfqToSupplierId : s.id
          return Number(id) == Number(p.rfqToSupplierId)
        })
        if (supplier && Number(supplier?.getOfferById(p.offerId)?.originalPartId) === Number(prtId)) {
          return !(p.isCancelled || p.isHasCredit)
        }
      })
    },
    isPartHasCredit(prt, supplier) {
      let id = supplier.rfqToSupplierId ? supplier.rfqToSupplierId : supplier.id
      let orderedPrt = _.find(this.rfq.partsOrdered, (p) => {
        return Number(p.rfqToSupplierId) === Number(id) && Number(p.offerId) == Number(prt.id)
      })
      return !!orderedPrt?.isHasCredit;
    },
    isCancelledPart (prt, supplier) {
      let id = supplier.rfqToSupplierId ? supplier.rfqToSupplierId : supplier.id
      let orderedPrt = _.find(this.rfq.partsOrdered, (p) => {
        return Number(p.rfqToSupplierId) === Number(id) && Number(p.offerId) == Number(prt.id)
      })
      return !!orderedPrt?.isCancelled
    },
    isOfferClickable (supplier, part) {
      let offer = supplier.getOffer(part)
      if (offer) {
        if (offer.isNoStock || offer.isNoLongerAvailable || offer.isNotApplicable) {
          return false
        }
        return (offer.isReady || (offer.isNoStock && offer.price > 0))
      }
      return false
      // return supplier.getOffer(part) && supplier.getOffer(part).isReady
    },
    isCanSelect(offer, supplier) {
      if (this.isNotCancelledPartOrHasCreditByOriginalPartId(offer.originalPartId)) {
        return false
      }
      return _.some(this.supplierPrice?.orderedSuppliers, (s) => {
        let id = s.rfqToSupplierId ? s.rfqToSupplierId : s.id
        let id2 = supplier.rfqToSupplierId ? supplier.rfqToSupplierId : supplier.id
        return Number(id) !== Number(id2) && _.some(s.offers, (ofr) => {
          return Number(ofr.originalPartId) === Number(offer.originalPartId) && this.isCancelledPartOrHasCredit(ofr, s)
        })
      })
    },
    priceClicked (supplier, part) {
      if (!this.isCanReOrder) {
        return
      }
      if (this.supplierPrice.isOfferOrdered(supplier.getOffer(part)) && !(!part.isOriginalPart && this.partIsWithNoCost(part))) {
        return
      }
      if (!this.isClickable) return
      if (part.isDelivery || (!part.isOriginalPart && this.partIsWithNoCost(part))) {
        return
      }
      // if (supplier.rfqToSupplierStatus !== 'Ordered') {
      //   return
      // }
      let offer = supplier.getOffer(part)

      if (!this.isCanSelect(offer, supplier)) {
        return;
      }
      if (!offer) return
      if (offer.isNoLongerAvailable || offer.isNotApplicable) {
        return
      }
      if (!offer.isReady) {
        if (part.isOriginalPart && !(offer.price > 0)) {
          console.log('Not Ready1')
          return
        } else if (!part.isOriginalPart && !offer.isDelivery) {
          console.log('Not Ready2')
          return
        }
      }
      //commented by #598
      // let isSelected = !!_.find(this.selectedParts, (p) => {
      //   return Number(p.partId) == Number(part.id) && Number(p.suppId) == Number(supplier.id)
      // })
      // if (!isSelected && Number(this.getCostPricePercentageForTooltip(offer, part, supplier)) > Number(this.purchasePriceSelector?.listPricePercents?.markUp) &&
      //     offer?.purchasePriceFigures?.sellPrice !== null && !offer?.purchasePriceFigures?.sellPriceCheck) {
      //   offer.purchasePriceFigures.sellPriceCheck = true
      //   this.onChangedOnCheckbox(1, offer, supplier, part)
      // }

      let offerId = offer.id
      let id = supplier.rfqToSupplierId ? supplier.rfqToSupplierId : supplier.id

      this.supplierPrice.selectOffer(id, part.id, part, offer, true)
      this.$emit('select', supplier.id, part.id, offerId, part, offer, supplier.type)

      return
    },
    supplierClicked(supplier) {
      if (!this.isClickable) return;
      this.supplierPrice.selectAllPartsFrom(supplier.id);
      //   this.$emit('select', supplier.id, null, null);
    },
    // methods related to Has Link and Linked Parts
    partIsLinked(part) {
      return this.supplierPrice.partIsLinked(part);
    },
    getPartLinkedName(part) {
      return this.supplierPrice.getPartLinkedName(part)
    },
    partIsWithNoCost(part) {
      return this.supplierPrice.partIsWithNoCost(part)
    },
    getAltPartName(partId) {
      return this.supplierPrice.getAltPartName(partId);
    },
    getPartNumber(partId) {
      return this.supplierPrice.getPartNumberForOrderedQuote(partId);
    },
  },
  props: {
    supplierPrice: {
      type: Object,
      required: true
    },
    rfq: {
      type: Object,
      default: () => {}
    },
    purchasePriceSelector: {
      type: Object,
      default: () => {}
    },
    rfqId: {
      type: String,
      default: () => ''
    },
    selectedPriceSet: {
      type: String,
      default: () => ''
    },
    rfqOrders: {
      type: Object,
      default: () => {
      }
    },
    manualParts: {
      type: Array,
      default: () => []
    },
    orderedPriceSets: {
      type: Array,
      default: () => []
    },
    isPartListTab: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      required: true
    },
    position: {
      type: Number,
      default: 1
    },
  },
  computed: {
    ...mapGetters({
      'stockOptions': 'stockOptions'
    }),
    isCanReOrder() {
      return this.rfq.status === 'Ordered' && this.isRfqSentToMultipleSupplier && _.some(this.rfq.partsOrdered, (p) => { return p.isCancelled || p.isHasCredit })
    },
    isRfqSentToMultipleSupplier () {
      if (this.rfq?.parts_to_suppliers) {
        return Object.keys(this.rfq.parts_to_suppliers).length > 1
      }
      return false
    },
    countOfSelectedParts () {
      return this.selectedParts.length
    },
    selectedParts () {
      let filteredSelectedParts = []
      _.forEach(this.supplierPrice.orderedSuppliers, (s) => {
        _.forEach(s.selectedParts, (id) => {
          _.forIn(s.parts, (part, partId) => {
            let offer = _.find(s.offers, (ofr) => {
              return Number(ofr.originalPartId) == Number(id)
            })
            let isAlreadyOrdered = _.some(this.rfq.partsOrdered, (p) => {return Number(p.offerId) === Number(offer?.id)})
            if (!isAlreadyOrdered && ((Number(partId) === 0 && part[id] && part[id].id && part[id].linkType !== 'withNoCost' && part[id].type !== 'delivery') || (part && part.id && Number(partId) === Number(id) && part.linkType !== 'withNoCost' && part.type !== 'delivery'))) {
              filteredSelectedParts.push(offer.id)
            }
          })
        })
      })
      return filteredSelectedParts
    },
    OEMSupplier() {
      return this.supplierPrice.OEMSupplier;
    },
    allParts() {
      let parts = this.supplierPrice.parts;//.concat(this.manualParts);
      _.forEach(parts, (prt) => {
        for (let id in this.supplierPrice.orderedSuppliers) {
          let supplier = this.supplierPrice.orderedSuppliers[id];
          if (this.supplierPrice.isOfferOrdered(supplier.getOffer(prt))) {
            prt.isOffer = true
          }
        }
      })
      return parts;
    },
    isExistListColumn() {
      return this.OEMSupplier ? true : false;
    }
  },
  mounted() {
    this.isClickable = (this.status === 'Ordered');
    window.addEventListener('resize', this.setLeftPosition)
    window.addEventListener('scroll', this.setLeftPosition);
    this.setLeftPosition()
    //if(!this.isClickable) this.supplierPrice.unselectAll();
    window.addEventListener('keydown', this.keyDownEvent);
    document.body.addEventListener('click', this.closeAllTooltipsByClick)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setLeftPosition);
    window.removeEventListener('scroll', this.setLeftPosition);
    window.removeEventListener('click', this.closeAllTooltipsByClick)
    window.removeEventListener('keydown', this.keyDownEvent);
  },
  watch: {
    status(newVal) {
      if (newVal === 'Ordered') {
        this.isClickable = true;
      } else {
        this.isClickable = false;
      }

    }
  }

}
</script>

<style>
.V3 .rfq-view-page .padding-top-and-bottom-0px {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.V3 .rfq-view-page .clickable-cell {
  cursor: pointer !important;
}
.V3 .rfq-view-page .clickable-cell:hover {
  cursor: pointer !important;
}
.V3 .form-control.rfq-ordered-order-btn {
  margin-left: 15px;
  background-color: #17ae58 !important;
  border-color: #17ae58 !important;
  font-weight: 700 !important;
  border-radius: 3px !important;
  cursor: pointer !important;
  color: #fff !important;
  opacity: 1 !important;
  width: 200px !important;
}

.V3 .form-control.rfq-ordered-insurer-btn {
  margin-left: 15px;
  background-color: white !important;
  border-color: #79b1bb !important;
  font-weight: 700 !important;
  border-radius: 3px !important;
  cursor: pointer !important;
  color: #1c1f39 !important;
  opacity: 1 !important;
  width: 200px !important;
  margin-bottom: 10px;
}

.V3 .form-control.rfq-ordered-order-btn:hover {
  background-color: #17ae58 !important;
  border-color: #17ae58 !important;
  color: #fff !important;
 }

.V3 .form-control.rfq-ordered-insurer-btn:hover {
  background-color: rgba(41, 187, 193, .1) !important;
  border-color: #79b1bb !important;
  color: #79b1bb !important;
 }

.V3 .form-control.rfq-ordered-insurer-btn:focus {
  box-shadow: none !important;
 }

.V3 .form-control.rfq-ordered-insurer-btn.active-insurer-btn {
  background-color: #79b1bb !important;
  border-color: #79b1bb !important;
  color: #fff !important;
  box-shadow: none !important;
}

.V3 .form-control.rfq-ordered-insurer-btn.active-insurer-btn:hover {
  background-color: #79b1bb !important;
  border-color: #79b1bb !important;
  color: #fff !important;
  box-shadow: none !important;
}

.V3 .form-control.rfq-ordered-order-btn-disabled {
  background-color: #74ce9b !important;
  border-color: #74ce9b !important;
}

.V3 .form-control.rfq-ordered-order-btn-disabled:hover {
  background-color: #74ce9b !important;
  border-color: #74ce9b !important;
}
</style>
<style lang="scss">
//@import "../../../../styles/style-components";

.part-list-item-alt-name {
  opacity: 0.5;
  color: #1C1F39;
  font-size: 12px;
  font-weight: bold;
}

.part-list-item-alt-name:first-child {
  padding-top: 5px;
}

.gray-button {
  background-color: #ccc !important;
  border-color: #ccc !important;
  color: #1C1F39 !important;

}

.part-list {
  font-family: "Inter", serif !important;
  font-weight: 600 !important;
}

.part-list .b-table-sticky-header .table.b-table > thead > tr > th {
  background-color: #e3e4e7 !important;

  &:hover {
    cursor: pointer;
  }
}

/* scroll style */
.part-list .b-table-sticky-header::-webkit-scrollbar {
  width: 7px;
}

.part-list .b-table-sticky-header--x::-webkit-scrollbar {
  width: unset;
  height: 7px;
}

.part-list .b-table-sticky-header::-webkit-scrollbar-thumb {
  background-color: #b7b8c0; /* #5e79ff; */
}

.part-list .b-table-sticky-header {
  scrollbar-width: thin;
  scrollbar-color: #b7b8c0; /* #5e79ff; */
}

/* end scroll style */

.supplier-column-highlight {
  background-color: #f5f5f5 !important;
}

th.selected-col:after,
th.selected-col:before,
th.selected-col:hover:after,
th.selected-col:hover:before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
}

th.selected-col:before {
  top: 0;
  border: 2px solid rgba(46, 169, 255, 1);
  /*height: 125px;*/
  height: 100%;
  z-index: 23;
}

th.selected-col {
  cursor: pointer;
}

button.order {
  height: 38px;
  width: 78px;
  border-radius: 3px;
}

.btn-link.order {
  color: var(--theme-color);
  padding: 0;

  &:hover {
    color: var(--theme-color);
    background-color: var(--gray-50);
  }
}

.part-list-item-priced {
  opacity: 1 !important;
  color: #1C1F39 !important;
}

.V3 .rfq-view-page .part-list-supplier-without-border:hover {
  // border: 1px solid #dee2e6 !important;
  // border-left-color: transparent !important;
  // border-right-color: transparent !important;
  // cursor: initial !important;
}

.ordered-button {
  opacity: 0.5 !important;
  background-color: #79B1BB !important;
  color: #1c1f39 !important;
}

.not-active-button {
  opacity: 1 !important;
  background-color: #fff !important;
  color: #79B1BB !important;
  border: solid 1px #79B1BB !important;
  font-weight: bold;
  border-radius: 3px;
}

.check-icon {
  color: #2DCA73;
}

.part-list-item-qty-ordered {
  display: inline-block;
  position: relative;
  width: 60px;
  top: -1px;
}

.part-list-item-qty-ordered span, .part-list-item-qty-ordered i {
  vertical-align: middle;
}


td.part-list-part-name {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;

  min-height: 47px !important;
  height: auto !important;

}

// .rfq-view-page .part-list .part-list-part-name {
.part-list-part-name {

  &__group {
    display: flex;
    flex-flow: column nowrap;
    align-items: top;
  }

  &.has-alt {
    .part-list-item-name {
      margin-bottom: 4px;
    }
  }

  &.linked {

    .part-list-part-name__name,
    .part-list-item-name {
      opacity: 0.5;
      color: #1C1F39;
    }
  }

  &-submitted {
    width: 40%;
    min-width: 200px;
  }

  &__icon-group {
    display: inline-block;
    margin: 0 0 0 auto;
    display: flex;
    align-items: center;
  }

  &__icon {
    font-size: 18px;
    color: grey;
    margin-right: 10px;
  }
}

.part-list-item-price {
  position: relative;

  .bx:hover {
    cursor: pointer;
  }

  &.has-icon {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  &__value {
    flex: 1 1 100%;

    .has-icon & {
      padding-left: 20px;
    }
  }

  &__icon-group {
    flex: 0 1 0px;
    position: relative;
    min-width: 20px;
    width: 20px;

    .has-icon & {
      display: inline-block;
    }
  }

}

</style>

<style scoped>
.price-red-blinking {
  color: red;
  animation: blinking-gray 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

@keyframes blinking-gray {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  80% {
    opacity: 1;
  }
}

.part-list-company-color{
  height: 5px;
  width: 80%;
  background: #083d77;
  border-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 14px;
}

.green-icon {
  color: rgb(60, 199, 169) !important;
}

.orange-icon {
  color: orange;
}

.icon-red-blinking {
  color: red;
  animation: blinking-red 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

.icon-orange-blinking {
  animation: blinking-orange 1s linear infinite;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

.blue-icon {
  color: #2f6da5 !important;
}

.gray-icon {
  color: rgb(121, 121, 121) !important;
}

@keyframes blinking-orange {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}

@keyframes blinking-red {
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
}


.part-list-supplier-non-non-active {
  background-color: #e3e4e7 !important;
  cursor: default !important;
}

.green-cell {
  color: #0cb458 !important;
}

.red-cell {
  color: red !important;
}

.disabled-qty {
  opacity: 0.5;
}

.supplier-name {
  position: relative;
  z-index: 100;
}

.V3 .rfq-view-page .part-list .part-list-supplier:active {
  background-color: unset;
}

.V3 .rfq-view-page .b-table-sticky-header > .table.b-table > thead > tr > th {
  top: -1px;
}

.color-grey,
.color-grey div {
  color: #ccc !important;
}
</style>
