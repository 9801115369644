<script>
/*eslint no-unused-vars: "off"*/
import Axios from 'axios'
import {mapGetters} from 'vuex'
import NProgress from 'nprogress'
import _ from "lodash";
import {isMobileOnly} from "mobile-device-detect";

export default {
  name: 'site-sidebar',
  data: function () {
    return {
      menu: {
        general: {
          default: false,
          dashboard: false,
          rfq: false,
          partOrders: false,
          suppliers: false,
          reports: false,
          advancedSettings: false,
          credits: false,
          customers: false,
        }
      }
    }
  },
  methods: {
    logout: function () {
      NProgress.start();
      let wrapper = document.getElementById('app-wrapper');
      wrapper.style.opacity = 0;
      setTimeout(() => {
        this.$store.dispatch('auth/logout')
      }, 500)
    },
    onLogoClick() {
      if (!this.isUserTypeRepairer && this.isPinnedImages) {
        let url = ''
        if (this.$route?.params?.tab && this.$route.params.tab === 'All') {
          url = '/s/rfqs#all'
        } else {
          url = '/s/rfqs#'
          url = url + _.toLower(this.currentRFQ.status)
        }
        this.$router.push({ path: url })
      }
    },
    clickOverlay: function () {
      console.log('overlay')
      let body = document.querySelector('body')
      let first = document.querySelector('.site-header .sidebar-toggle-first')
      if (first) {
        first.classList.remove('active')
      }
      body.classList.remove('site-sidebar-opened')
    },
    loadMenuAccess: function () {
      NProgress.start()
      Axios.get('/ir/auth/menu')
          .then(response => {
            if (response.status == 200) {
              if (response.data) {
                this.menu = response.data
              }
            }
            NProgress.done()
          })
          .catch(error => {
            NProgress.done()
          })
    },
    updateSettings: function () {
      NProgress.start()
      Axios.post('/ir/user/config', this.settings)
          .then(response => {
            NProgress.done()
            toastr.success(response.data.msg)
          })
          .catch(error => {
            NProgress.done()
            toastr.error(error)
          })
    }
  },
  computed: {
    ...mapGetters({
      isUserTypeRepairer: 'currentUser/isUserTypeRepairer',
      isPinnedImages: 'supplier/rfq/isPinnedImages',
      currentRFQ: 'supplier/rfq/getCurrentRFQ',
      currentCompany: 'currentCompany/getDetails',
    }),
    path: function () {
      return this.$route.path
    },
    isMenu: function () {
      return this.menu
    }
  },
  watch: {
    path: function () {
    }
  },
  mounted: function () {
    this.loadMenuAccess()
    if (!isMobileOnly) {
      window.HubSpotConversations?.clear({resetWidget: true});
    }
  }
}
</script>

<template>
  <div>
    <!-- Sidebar -->
    <div class="site-overlay" @click="clickOverlay"></div>
    <div class="site-sidebar" @click="clickOverlay">
      <div style>
        <img src="@/assets/logos/partssearch-logo-transparent-backgroud.svg" @click="onLogoClick" :class="{'cursor-ptr': !isUserTypeRepairer && isPinnedImages}" class="navbar-brand-logo-svg"/>
      </div>

      <div v-if="menu.general.default" class="custom-scroll custom-scroll-dark">
        <!--        RepairerType-->
        <ul v-if="isUserTypeRepairer" class="sidebar-menu">
          <li v-if="menu.general.dashboard"
              :class="{'active': path == '/r'}">
            <router-link to="/r" class="waves-effect waves-light">
              <span class="s-icon">
                <i class="bx bxs-dashboard"></i>
              </span>
              <span class="s-text">Dashboard</span>
              <!--</a>-->
            </router-link>
          </li>
          <li v-if="menu.general.rfq"
              :class="{'active': path == '/r/rfqs'}">
            <router-link to="/r/rfqs" class="waves-effect waves-light">
              <span class="s-icon">
                <i class="bx bx-spreadsheet"></i>
              </span>
              <span class="s-text">Request for Quotes</span>
            </router-link>
          </li>
          <li v-if="menu.general.partOrders"
              :class="{'active': path == '/r/orders'}">
            <router-link to="/r/orders" class="waves-effect waves-light">
              <span class="s-icon">
                   <i class="bx bx-spreadsheet"></i>
              </span>
              <span class="s-text">Part Orders</span>
            </router-link>
          </li>
          <template v-if="currentCompany.id == 16">
            <li v-if="menu.general.suppliers"
                :class="{'active': path == '/r/suppliers'}">
              <router-link to="/r/suppliers" class="waves-effect waves-light">
              <span class="s-icon">
                <i class="bx bx-carousel"></i>
              </span>
                <span class="s-text">Suppliers</span>
              </router-link>
            </li>
          </template>
          <template v-if="currentCompany.id == 10">
            <li v-if="menu.general.credits"
                :class="{'active': path == '/r/credits'}">
              <router-link to="/r/credits" class="waves-effect waves-light">
              <span class="s-icon">
                <i class="bx bx-info-square"></i>
              </span>
                <span class="s-text">Credit Management</span>
              </router-link>
            </li>
            <li v-if="menu.general.suppliers"
                :class="{'active': path == '/r/suppliers'}">
              <router-link to="/r/suppliers" class="waves-effect waves-light">
              <span class="s-icon">
                <i class="bx bx-carousel"></i>
              </span>
                <span class="s-text">Suppliers</span>
              </router-link>
            </li>
            <li v-if="menu.general.reports"
                :class="{'active': path == '/r/reports'}">
              <router-link to="/r/reports" class="waves-effect waves-light">
              <span class="s-icon">
                <i class='bx bx-bar-chart-alt-2'></i>
              </span>
                <span class="s-text">Reports</span>
              </router-link>
            </li>
            <li v-if="menu.general.advancedSettings"
                :class="{'active': path == '/settings'}">
              <router-link to="/settings" class="waves-effect waves-light">
              <span class="s-icon">
                <i class="bx bx-book-content"></i>
              </span>
                <span class="s-text">Advanced Settings</span>
              </router-link>
            </li>
          </template>
        </ul>
        <!--SupplierType-->
        <ul v-else class="sidebar-menu">
          <li v-if="menu.general.dashboard"
              :class="{'active': path == '/s'}">
            <router-link to="/s" class="waves-effect waves-light">
              <span class="s-icon">
                <i class="bx bxs-dashboard"></i>
              </span>
              <span class="s-text">Dashboard</span>
            </router-link>
          </li>
          <li v-if="menu.general.rfq"
              :class="{'active': path == '/s/rfqs'}">
            <router-link to="/s/rfqs" class="waves-effect waves-light">
              <span class="s-icon">
                <i class="bx bx-spreadsheet"></i>
              </span>
              <span class="s-text">Request for Quotes</span>
            </router-link>
          </li>
          <li v-if="menu.general.partOrders"
              :class="{'active': path == '/s/orders'}">
            <router-link to="/s/orders" class="waves-effect waves-light">
              <span class="s-icon">
                <i class="bx bx-spreadsheet"></i>
              </span>
              <span class="s-text">Parts Orders</span>
            </router-link>
          </li>
          <li v-if="menu.general.credits"
              :class="{'active': path == '/s/credits'}">
            <router-link to="/s/credits" class="waves-effect waves-light">
              <span class="s-icon">
                <i class="bx bx-info-square"></i>
              </span>
              <span class="s-text">Credits</span>
            </router-link>
          </li>
          <li v-if="menu.general.customers"
              :class="{'active': path == '/s/customers'}">
            <router-link to="/s/customers" class="waves-effect waves-light">
              <span class="s-icon">
                <i class="bx bx-carousel"></i>
              </span>
              <span class="s-text">Customers</span>
            </router-link>
          </li>
          <li v-if="menu.general.advancedSettings"
              :class="{'active': path == '/settings'}">
            <router-link to="/settings" class="waves-effect waves-light">
              <span class="s-icon">
                <i class="bx bx-book-content"></i>
              </span>
              <span class="s-text">Advanced Settings</span>
            </router-link>
          </li>
        </ul>


        <div  v-if="currentCompany.id != 10" class="help-support-menu-block" style="bottom: 282px; margin-left: 54px">
          <a @click.prevent="logout" class="help-support-menu-text" href="#">
            <p style="font-size: 16px" class="m-0 mb-1">Sign out</p>
          </a>
        </div>

        <div class="help-support-menu-block">
          <i class='bx bx-help-circle'></i>
          <a class="help-support-menu-text" href="tel:0272500858">
            <p class="m-0 mb-1">Help | Support</p>
            <p class="m-0">02 7250 0858</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.skin-3 .site-sidebar {
  background-color: #131923;
  /*background: linear-gradient(180deg, #1C1F3A 0%, #161931 53.69%, #282C48 100%);*/
}

.skin-3 .site-sidebar .sidebar-menu .menu-title {
  color: #FFFFFF;
  opacity: 0.25;
  margin-bottom: 0px;
  padding-bottom: 2px;
}

.skin-3 .site-sidebar .sidebar-menu li > a {
  color: #FFFFFF;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
}

.skin-3 .site-sidebar .sidebar-menu > li.active > a, .skin-3.compact-sidebar .site-sidebar .sidebar-menu > li:hover {
  /*background-color: #BBC2CE;*/
  background: linear-gradient(135deg, #7267E7 0%, #6259EF 49.12%, #4E45EE 100%);
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 5px;
  color: #FFFFFF;
  margin-top: 1px;
  height: 35px;
}

.skin-3 .site-sidebar .sidebar-menu > li:hover > a {
  color: #FFFFFF;
}

.skin-3 .site-sidebar .sidebar-menu > li > a .s-icon i {
  color: #FFFFFF;
  font-size: 21px;
}

/* Version3 */
.V3 .site-sidebar {
  /*        background-color: #142742;*/
  margin-top: 0px;
  /* background: linear-gradient(180deg, #1C1F3A 0%, #161931 53.69%, #282C48 100%);*/
  background: none;;
  background-color: #1B1E37;
}

.V3 .site-sidebar .sidebar-menu .menu-title {
  color: #FFFFFF;
  opacity: 0.25;
}

.V3 .site-sidebar .sidebar-menu li > a {
  color: #FFFFFF;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
}

.V3 .site-sidebar .sidebar-menu > li.active > a {
  /* background-color: #BBC2CE; */
  background: var(--supplier-color); /* // linear-gradient(135deg, #7267E7 0%, #6259EF 49.12%, #4E45EE 100%); */
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 5px;
  color: #FFFFFF;
  margin-top: 1px;
}

.V3 .theme-repairer .site-sidebar .sidebar-menu > li.active > a {
  /*background: var(--repairer-color);*/
  color: var(--repairer-color);
}

.V3 .site-sidebar .sidebar-menu > li:hover > a {
  color: #FFFFFF;
}

.V3 .site-sidebar .sidebar-menu > li > a .s-icon i {
  color: #FFFFFF;
  font-size: 21px;
}

.V3 .site-sidebar .help-support-menu-block {
  position: absolute;
  bottom: 200px;
  margin-left: 15px;
  padding: 0 1.25rem;
  height: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #FFFFFF;
}

.V3 .theme-supplier .site-sidebar .help-support-menu-block:hover .bx-help-circle,
.V3 .theme-supplier .site-sidebar .help-support-menu-block:hover .help-support-menu-text {
  color: #29BBC1;
}

.V3 .theme-repairer .site-sidebar .help-support-menu-block:hover .bx-help-circle,
.V3 .theme-repairer .site-sidebar .help-support-menu-block:hover .help-support-menu-text {
  color: #79B1BB;
}

.V3 .site-sidebar .help-support-menu-block .bx-help-circle {
  color: #FFFFFF;
  font-size: 21px;
  float: left;
  margin-right: 1.125rem;
}

.V3 .site-sidebar .help-support-menu-text {
  color: white;
  font-size: 14px;
}

.V3 .site-sidebar .help-support-menu-text p:first-child{
  padding-top: 23px;
}

.V3 .V3.compact-sidebar .site-sidebar .help-support-menu-block {
  padding: 0 1.25rem 0 0.75rem;
}

@media (min-width: 768px) {
  .V3 .V3.compact-sidebar .help-support-menu-text {
      display: none;
  }
  .V3 .V3.compact-sidebar .site-sidebar .help-support-menu-block .bx-help-circle {
    font-size: 1.5rem;
    margin-left: 0px;
  }
  .V3 .V3.compact-sidebar .site-sidebar .help-support-menu-block {
    font-size: 1.5rem;
    margin-left: 15px;
  }
}

.V3 .V3.compact-sidebar .theme-supplier .site-sidebar .help-support-menu-block:hover {
    background: none;
    width: 290px;
    background-color: #131923;
    border: 1px solid #29BBC1;
    border-radius: 5px 5px 5px 5px;
    padding: 7px 16px 7px 10px;

}

.V3 .V3.compact-sidebar .theme-repairer .site-sidebar .help-support-menu-block:hover {
    border: 1px solid #79B1BB;
    background: none;
    width: 290px;
    background-color: #131923;
    border-radius: 5px 5px 5px 5px;
    padding: 7px 16px 7px 10px;
}

.V3 .V3.compact-sidebar .site-sidebar .help-support-menu-block:hover .help-support-menu-text {
  display: flex;
  font-size: 14px;
  padding-bottom: 2px;
}

.V3 .V3.compact-sidebar .site-sidebar .help-support-menu-block:hover .help-support-menu-text p:first-child {
  padding-top: 0;
  padding-right: 30px;
  margin-bottom: 0 !important;
}
</style>

<style>
@media screen and (max-height: 450px) {
  .help-support-menu-block {
    position: static !important;
    margin-left: 22px !important;
  }
  .V3 .fixed-sidebar .site-sidebar {
    overflow-y: scroll;
  }
}
</style>
